import { requestSansStore } from '@united-talent-agency/julius-frontend-store';

export const get = (path, query) => {
  return requestSansStore(path, 'GET', null, query);
};

export const post = (path, payload) => {
  return requestSansStore(path, 'POST', payload, null);
};

export const put = (path, payload, query) => {
  return requestSansStore(path, 'PUT', payload, query);
};

export const patch = (path, payload, query) => {
  return requestSansStore(path, 'PATCH', payload, query);
};

export const remove = (path, options, query) => {
  return requestSansStore(path, 'DELETE', options, query);
};
