/**
 * Single registry for all data-cy tags used within employees & consumed dependencies
 */
module.exports = {
  ICONS: {
    PHONE: 'phone',
    EMAIL: 'email',
    LANDLINE: 'landline',
  },
  CONTACT_TABLE: {
    CONTACT_TABLE: 'contact-table',
  },
  FILTERS: {
    FILTER_INPUT: 'filter-input',
    FILTER_DROPDOWN: 'filter-dropdown',
    SELECTED_FILTER: 'selected-filter',
  },
  HEADER: {
    CONTAINER: 'header-container',
    SEARCH_BOX: 'search-box',
    MENU_OPENER: 'menu-opener',
    MENU_CONTAINER: 'menu-container',
  },
  EMPLOYEES_LIST: {
    LIST_CONTAINER: 'employees-list-container',
    SELECTED_FILTER_CHIPS: 'selected-filter-chips',
    SEARCH_RESULT: 'search-result',
    RESET_BUTTON: 'reset-button',
  },
  EMPLOYEE_CARD: {
    EMPLOYEE_CARD: 'employee-card',
    EMPLOYEE_CARD_FACE: 'employee-card-face',
    EMPLOYEE_PHOTO: 'employee-photo',
    EMPLOYEE_INFO: 'employee-info',
    EMPLOYEE_PRONOUN: 'employee-pronoun',
    EMPLOYEE_LOCATION: 'employee-location',
    CLIENT_LIST_BUTTON: 'client-list-button',
    AUXILLARY_PERSON: 'employee-auxillary-person',
    AUXILLARY_PERSON_PHOTO: 'employee-auxillary-person-photo',
  },
  CLIENT_LIST: {
    CLIENT_LIST_MODAL: 'client-list-modal',
    PROFILE_INFO_NAME: 'profile-info-name',
    CLIENT_NAME: 'client-list-name',
  },
};
