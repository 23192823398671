import { HttpLink, ApolloClient, InMemoryCache, fromPromise, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';

import packageJson from '../../package.json';
import { fetchGraphQLTokens, isGraphQLTokenExpired } from './tokens-service';

const { version } = packageJson;
const { REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL } = process.env;
const GRAPHQL_TOKENS_STORAGE_KEY = 'uta.graphQLTokens';

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const httpLink = new HttpLink({
  uri: REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL,
});

const authMiddleware = setContext(async () => {
  const headers = {};

  let graphQLTokens = await getGraphQLTokens();

  if (graphQLTokens) {
    headers.login_interface = 'WEB';
    headers.Authorization = `bearer ${graphQLTokens.jwtToken}`;
    headers['Ocp-Apim-Subscription-Key'] = graphQLTokens.apimToken;
  }

  return { headers };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    console.log(`[GraphQL errors]: ${graphQLErrors}`);
  }

  if (networkError) {
    let forward$;

    switch (networkError.statusCode) {
      case 401:
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            fetchGraphQLTokens()
              .then(({ body: graphQLTokens }) => {
                const graphQLTokensString = JSON.stringify(graphQLTokens);
                localStorage.setItem(GRAPHQL_TOKENS_STORAGE_KEY, graphQLTokensString);
                resolvePendingRequests();
                return graphQLTokens;
              })
              .catch(() => {
                localStorage.removeItem(GRAPHQL_TOKENS_STORAGE_KEY);
                pendingRequests = [];
                return;
              })
              .finally(() => (isRefreshing = false))
          ).filter((value) => Boolean(value));
        } else {
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve());
            })
          );
        }

        return forward$.flatMap(() => forward(operation));
      default:
    }
  }
});

const client = new ApolloClient({
  version,
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  name: 'employees-web',
});

const getGraphQLTokens = async () => {
  try {
    let graphQLTokensString = localStorage.getItem(GRAPHQL_TOKENS_STORAGE_KEY);
    let graphQLTokens;

    if (graphQLTokensString) {
      graphQLTokens = JSON.parse(graphQLTokensString);
      ({ body: graphQLTokens } = await isGraphQLTokenExpired(graphQLTokens.jwtToken));
    } else {
      ({ body: graphQLTokens } = await fetchGraphQLTokens());
    }

    graphQLTokensString = JSON.stringify(graphQLTokens);
    localStorage.setItem(GRAPHQL_TOKENS_STORAGE_KEY, graphQLTokensString);
    return graphQLTokens;
  } catch (e) {
    localStorage.removeItem(GRAPHQL_TOKENS_STORAGE_KEY);
    throw e;
  }
};

export { client, getGraphQLTokens };
