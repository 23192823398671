import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'normalize.css/normalize.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-day-picker/lib/style.css';
import 'react-simple-dropdown/styles/Dropdown.css';
import { Provider } from 'react-redux';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { initializeDataDog } from './support/dataDog';
import { createStoreWithOptions } from '@united-talent-agency/julius-frontend-store';
import { OnyxThemeProvider } from '@united-talent-agency/components';

import './styles/card.css';
import './styles/scroll.css';
import './styles/select.css';
import './styles/popover.css';

import Routes from './routes';

import { apiServerUrl } from './support/urls';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { client } from './graphql/client';

const store = createStoreWithOptions({ apiServerUrl });
const LAUNCHDARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": employees.unitedtalent.com
 *    "staging": employees.staging.unitedtalent.com
 *    "dev": employees.dev.unitedtalent.com
 *    "local": localhost
 */
initializeDataDog(window.location.hostname, ['staging', 'prod']);

function App() {
  return (
    <LDProvider clientSideID={LAUNCHDARKLY_CLIENT_ID}>
      <Provider store={store}>
        <OnyxThemeProvider>
          <ApolloProvider client={client}>
            <Router>
              <Routes />
            </Router>
          </ApolloProvider>
        </OnyxThemeProvider>
      </Provider>
    </LDProvider>
  );
}

render(<App />, document.getElementById('app'));
