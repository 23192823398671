import { gql } from '@apollo/client';

const GET_SPACE_IQ = gql`
  query GetSpaceIQ($where: SpaceIQInput) {
    getSpaceIQ(where: $where) {
      spaceIQ {
        interactiveMap
        location
        mapUrl
        phone
      }
      success
    }
  }
`;

export { GET_SPACE_IQ };
