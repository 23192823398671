import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation Login(
    $email: String!
    $currentToken: String!
    $loginInterface: LoginInterface
    $deviceId: String
  ) {
    login(
      email: $email
      currentToken: $currentToken
      loginInterface: $loginInterface
      deviceId: $deviceId
    ) {
      success
    }
  }
`;

const LOGOUT = gql`
  mutation Logout(
    $logoutEmail2: String!
    $logoutCurrentToken2: String!
    $logoutDeviceId2: String
    $logoutLoginInterface2: LoginInterface
  ) {
    logout(
      email: $logoutEmail2
      currentToken: $logoutCurrentToken2
      deviceId: $logoutDeviceId2
      loginInterface: $logoutLoginInterface2
    ) {
      success
    }
  }
`;

export { LOGIN, LOGOUT };
